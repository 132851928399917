
import { Vue, Component, Prop } from "vue-property-decorator";
import AdmButton from "@afe/admirals-dev/src/components/AdmButton/AdmButton";
import AdmSymbol from "@afe/admirals-dev/src/components/AdmSymbol";
import CheckboxField from "@/forms/DemoUniversalForm/CheckboxField";
import Cookies from "js-cookie";

@Component({
  components: { CheckboxField, AdmSymbol, AdmButton }
})
export default class extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) readonly p1!: string;
  @Prop(String) readonly p2!: string;
  @Prop(String) readonly p3!: string;
  @Prop(String) readonly p4!: string;
  @Prop(String) readonly account1!: string;
  @Prop(String) readonly account3!: string;
  @Prop(String) readonly account4!: string;
  @Prop(String) readonly account5!: string;
  @Prop(String) readonly account6!: string;
  @Prop(String) readonly checkbox!: string;
  @Prop(String) readonly submit!: string;

  isShown = false;
  notShow = false;

  onClose() {
    if (this.notShow) {
      Cookies.set("fb_stolen_account_reminder", "1", { expires: 365 });
    }
    this.isShown = false;
    document.body.style.overflow = "initial";
  }

  mounted() {
    this.isShown = !Cookies.get("fb_stolen_account_reminder");

    if (this.isShown) {
      document.body.style.overflow = "hidden";
    }
  }
}
