import Vue from "vue";
import Cookies from "js-cookie";
import Vuelidate from "vuelidate";
import "./public-path";
import "./MainMenu/index";

import { AmTypography } from "@afe/amui/src/components/Typography";
import { AmResponsive } from "@afe/amui/src/components/Responsive";
import {
  AmAccordion,
  AmAccordionItem
} from "@afe/amui/src/components/Accordion";
import { AmIcon } from "@afe/amui/src/components/Icon";
import { AmButton } from "@afe/amui/src/components/Button";

import ScrollButton from "./ScrollButton/index.vue";
import SearchButton from "./SearchButton/index.vue";
import AdmButton from "./AdmButton/index.vue";
import AdmTabsSlots from "./AdmTabsSlots/index.vue";
import Anchor from "./Anchor/index.vue";
import DocumentLink from "./DocumentLink/index.vue";
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher.vue";
import RiskWarning from "./RiskWarning/index.vue";
import ScrollButtonIcon from "./ScrollButtonIcon/index.vue";
import TabsSlots from "./TabsContent/TabsSlots.vue";
import HashBlocks from "./HashBlocks/HashBlocks.vue";
import HashBlock from "./HashBlocks/HashBlock.vue";
import { Swiper, SwiperSlide } from "./Swiper";
import HomeTabs from "./HomeTabs/HomeTabs.vue";
import VueQuotes from "./Quotes/Quotes.vue";
import CryptoWidget from "./CryptoWidget/CryptoWidget.vue";
import CalculatorWidget from "./CalculatorWidget/CalculatorWidget.vue";
import ButtonOnScroll from "./ButtonOnScroll/index.vue";
import Regulator from "./Regulator/index.vue";
import Hint from "./Hint/index.vue";
import AccountTypes from "./AccountTypes/AccountTypes.vue";
import TabsContent from "./TabsContent/TabsContent.vue";
import TabsScroll from "./TabsScroll/TabsScroll.vue";
import ReadMore from "./ReadMore/index.vue";
import ZebraTable from "./ZebraShadowTable/index.vue";
import ArticleShare from "./Article/Share.vue";
import ArticleRating from "./Article/Rating.vue";
import FacebookIcon from "./Article/FacebookIcon.vue";
import LinkedinIcon from "./Article/LinkedinIcon.vue";
import CustomTabs from "./CustomTabs/index.vue";
import YoutubeVideo from "./YoutubeVideo/index.vue";
import TwitterIcon from "@/components/Article/TwitterIcon.vue";
import YoutubeIcon from "@/components/Article/YoutubeIcon.vue";
import TelegramIcon from "@/components/Article/TelegramIcon.vue";
import InstagramIcon from "@/components/Article/InstagramIcon.vue";
import SelectSwither from "./SelectSwither/index.vue";
import DepositWithdrawal from "./DepositWithdrawal/Index.vue";
import AdmAccordion from "./Accordion/Accordion.vue";
import AdmAccordionItem from "./Accordion/AccordionItem.vue";
import AdmTypography from "@afe/admirals-dev/src/components/AdmTypography";
import AdmIconButton from "@afe/admirals-dev/src/components/AdmIconButton";
import AdmSymbol from "@afe/admirals-dev/src/components/AdmSymbol";
import AdmMarketButton from "@afe/admirals-dev/src/components/AdmMarketButton";
import Details from "./Details/index.vue";
import VersionSwitcher from "./VersionSwitcher/index.vue";
import TradeCharts from "./TradeCharts/TradeCharts.vue";
import StolenAccounts from "./StolenAccounts/index.vue";

// @ts-ignore
Vue.use(Vuelidate);
Vue.config.productionTip = false;

const widgets: Record<string, any> = {
  accordion: AmAccordion,
  "scroll-button": ScrollButton,
  "document-link": DocumentLink,
  "language-switcher": LanguageSwitcher,
  "risk-warning": RiskWarning,
  "ui-icon": AmIcon,
  regulator: Regulator,
  "tabs-slots": TabsSlots,
  "scroll-button-icon": ScrollButtonIcon,
  "hash-blocks": HashBlocks,
  "hash-block": HashBlock,
  anchor: Anchor,
  "home-tabs": HomeTabs,
  "app-quotes": VueQuotes,
  "crypto-widget": CryptoWidget,
  "calculator-widget": CalculatorWidget,
  "trade-charts": TradeCharts,
  "button-on-scroll": ButtonOnScroll,
  "ui-button": AmButton,
  "am-responsive": AmResponsive,
  "swiper-widget": Swiper,
  "tabs-content": TabsContent,
  "tabs-scroll": TabsScroll,
  "read-more": ReadMore,
  "spreads-comparison-table": ZebraTable,
  "article-share": ArticleShare,
  "article-rating": ArticleRating,
  "facebook-icon": FacebookIcon,
  "linkedin-icon": LinkedinIcon,
  "twitter-icon": TwitterIcon,
  "youtube-icon": YoutubeIcon,
  "telegram-icon": TelegramIcon,
  "instagram-icon": InstagramIcon,
  "custom-tabs": CustomTabs,
  "youtube-video": YoutubeVideo,
  "select-switcher": SelectSwither,
  "deposit-withdrawal": DepositWithdrawal,
  "search-button": SearchButton,
  "adm-accordion": AdmAccordion,
  "adm-tabs-slots": AdmTabsSlots,
  "adm-button": AdmButton,
  "adm-symbol": AdmSymbol,
  "adm-icon-button": AdmIconButton,
  "adm-market-button": AdmMarketButton,
  "details-widget": Details,
  "version-switcher": VersionSwitcher,
  "stolen-accounts": StolenAccounts
};

const asyncWidgets: Record<string, any> = {
  sitemap: () => import("./Sitemap/index.vue"),
  podcasts: () => import("./Podcasts/podcasts.vue"),
  instruments: () => import("./ContractSpecifications/Instruments.vue"),
  popup: () => import("./Popup/Index.vue"),
  "article-toc": () => import("./ArticleToc/ArticleToc.vue"),
  "article-cards": () => import("./ArticleCards/ArticleCards.vue"),
  "article-card": () => import("./ArticleCards/ArticleCard.vue"),
  "analytic-news-cards": () =>
    import("./AnalyticNewsCards/AnalyticNewsCards.vue"),
  "education-cards": () => import("./EducationCards/EducationCards.vue"),
  "education-card": () => import("./EducationCards/EducationCard.vue"),
  "login-form": () => import("../forms/LoginForm/index.vue"),
  "sign-up-form-single": () => import("../forms/SignUpFormSingle/index.vue"),
  "sign-up-latam-expo": () => import("../forms/SignUpFormLatamExpo/index.vue"),
  "sign-up-form-phone": () => import("../forms/SignUpFormPhone/index.vue"),
  "sign-up-form-social": () => import("../forms/SignUpFormSocial/index.vue"),
  "sign-up-form-simple": () => import("../forms/SignUpFormSimple/index.vue"),
  "sign-up-form": () => import("../forms/SignUpForm/index.vue"),
  "education-form": () => import("../forms/EducationForm/index.vue"),
  "activate-lead-form": () => import("../forms/ActivateLeadForm/index.vue"),
  "partner-form": () => import("../forms/PartnerSignup/index.vue"),
  "white-label-form": () => import("../forms/WhiteLabelForm/index.vue"),
  "dubai-expo-form": () => import("../forms/DubaiExpoForm/index.vue"),
  "demo-form": () => import("../forms/DemoForm/index.vue"),
  "crypto-form": () => import("../forms/CryptoForm/index.vue"),
  "premium-analytics-form": () => import("../forms/PremiumAnalytics/index.vue"),
  "affiliate-form": () => import("../forms/AffiliateSignup/index.vue"),
  "trading-central-form": () => import("../forms/TradingCentral/index.vue"),
  "islamic-form": () => import("../forms/SignUpForm/index.vue"),
  "mt-se-form": () => import("../forms/MtSeForm/index.vue"),
  "ebook-form": () => import("../forms/EbookForm/index.vue"),
  "reset-password-form": () => import("../forms/ResetPassword/index.vue"),
  "webtrader-form": () => import("../forms/MTWebTrader/mt-webtrader-form.vue"),
  "zth-form": () => import("../forms/ZthForm/index.vue"),
  "masters-of-trading": () => import("../forms/TeachableForm/index.vue"),
  "mactrader-form": () => import("../forms/MacTraderForm/index.vue"),
  "campaign-form": () => import("../forms/CampaignForm/index.vue"),
  "invest-form": () => import("../forms/InvestForm/index.vue"),
  "subscription-form": () => import("../forms/SubscriptionForm/index.vue"),
  "tradedays-form": () => import("../forms/TradeDaysForm/index.vue"),
  "demo-universal-form": () => import("../forms/DemoUniversalForm/index.vue"),
  "marketing-registration-form": () =>
    import("../forms/MarketingRegistrationForm/index.vue"),
  "sign-up-form-finexware": () =>
    import("../forms/SignUpFormFinexware/index.vue"),
  "cert-popup": () => import("./ESG/CertPopup.vue"),
  "form-popup": () => import("./ESG/FormPopup.vue"),
  "youtube-full-width": () => import("./YoutubeFullWidth/YoutubeFullWidth.vue"),
  "app-banner": () => import("./AppBanner/index"),
  "details-widget": () => import("./Details/index.vue"),
  "version-switcher": () => import("./VersionSwitcher/index.vue"),
  "spain-popup-form": () => import("../forms/SpainPopUpForm/index.vue"),
  "country-switcher-dev-form": () =>
    import("../forms/CountrySwitcherDevForm/index.vue")
};

const components: Record<string, any> = {
  "am-icon": AmIcon,
  "accordion-items": AmAccordion,
  "accordion-item": AmAccordionItem,
  typography: AmTypography,
  "am-button": AmButton,
  "app-tabs-slots": TabsSlots,
  swiper: Swiper,
  "swiper-widget": Swiper,
  "swiper-slide": SwiperSlide,
  "app-scroll-button": ScrollButton,
  hint: Hint,
  "account-types": AccountTypes,
  "language-switcher": LanguageSwitcher,
  "adm-accordion": AdmAccordion,
  "adm-accordion-item": AdmAccordionItem,
  "adm-typography": AdmTypography,
  "adm-symbol": AdmSymbol,
  "adm-tabs-slots": AdmTabsSlots,
  "crypto-widget": CryptoWidget,
  "adm-button": AdmButton,
  "adm-icon-button": AdmIconButton,
  "adm-market-button": AdmMarketButton
};

Object.keys(widgets).forEach(name => {
  [...document.getElementsByTagName(name)].forEach(el => {
    new Vue({
      el,
      components: {
        [name]: widgets[name],
        ...components
      }
    });
  });
});

Object.keys(asyncWidgets).forEach(name => {
  [...document.getElementsByTagName(name)].forEach(el => {
    asyncWidgets[name]().then((component: any) => {
      new Vue({
        el,
        components: {
          [name]: component.default,
          ...components
        }
      });
    });
  });
});

// @ts-ignore
window.getCookie = Cookies.get;
// @ts-ignore
window.setCookie = Cookies.set;
